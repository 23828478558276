import { useState, useEffect, useRef } from 'react';

// types
import { TilesPayload } from '../content-elements-payload';

// components
import TilesView from './TilesView';

// utils
import { useIsMobile, useIsTablet } from 'components/App/SizeProvider';
import { isEmpty } from 'utils/is-empty';

export function Tiles({ sizes, tiles, ...props }: Readonly<TilesPayload>) {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [currentTiles, setCurrentTiles] = useState(tiles ?? []);
  const [currentSize, setCurrentSize] = useState<{ size: number; addOnLoad: number } | null>(null);
  const { showLoadMoreButton } = props;
  const ref = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (!isEmpty(sizes)) {
      if (isMobile) setCurrentSize({ size: sizes.mobile, addOnLoad: 1 });
      if (isTablet) setCurrentSize({ size: sizes.tablet, addOnLoad: 2 });
      if (!isMobile && !isTablet) setCurrentSize({ size: sizes.desktop, addOnLoad: 3 });
    }
  }, [isMobile, isTablet, sizes]);

  useEffect(() => {
    if (!ref.current) {
      // set first loaded screensize to not update currentTiles when screenSize changes
      ref.current = currentSize ? currentSize.size : undefined;
    }

    if (
      showLoadMoreButton &&
      currentSize &&
      ref.current === currentSize.size &&
      tiles.length > currentSize.size
    ) {
      const tilesToShow = tiles.slice(0, currentSize.size);
      setCurrentTiles(tilesToShow);
    }
  }, [currentSize, tiles, showLoadMoreButton]);

  /**
   * Add new tiles on load more
   */
  const loadOneMoreRow = () => {
    const newLength = currentTiles.length + (currentSize?.addOnLoad || 0);
    const newTilesToShow = tiles?.slice(0, newLength);
    setCurrentTiles(newTilesToShow ?? []);
  };

  return (
    <TilesView
      {...props}
      currentTiles={currentTiles}
      loadOneMoreRow={loadOneMoreRow}
      isMobile={isMobile}
      isTablet={isTablet}
    />
  );
}
