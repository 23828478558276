import { Settings } from 'react-slick';

export type SlickSettignsParams = {
  slides: object[];
  autoplay?: boolean;
  showArrowsMobile?: boolean;
  onSwipe?: Settings['onSwipe'];
  afterChange?: Settings['afterChange'];
};

export function slickSettings({
  autoplay = false,
  showArrowsMobile = true,
  onSwipe,
  slides,
  afterChange,
}: SlickSettignsParams): Settings {
  return {
    autoplay: autoplay,
    pauseOnHover: true,
    swipeToSlide: true,
    onSwipe,
    afterChange,
    dots: true,
    arrows: true,
    slidesToShow: Math.min(slides.length, 3),
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992, // Breakpoint "large"
        settings: {
          slidesToShow: Math.min(slides.length, 2),
          slidesToScroll: Math.min(slides.length, 2),
          arrows: showArrowsMobile,
        },
      },
      {
        breakpoint: 480, // Breakpoint "small"
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: showArrowsMobile,
        },
      },
    ],
  };
}

export const CLASS_MAPPING = {
  '3-per-row': 'three-columns',
  '2-per-row': 'two-columns',
  '1-per-row': 'single-column',
  carousel: 'carousel',
};
