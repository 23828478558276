import { useRef, useState } from 'react';
import key from 'weak-key';
import SlickSlider from 'react-slick/lib';

// types
import type { TilesViewProps } from './types';

// components
import TileLink from './Link/TileLink';
import TileVideo from './Video/TileVideo';
import { Title } from '../Title/Title';
import LoadMoreButton from 'components/LoadMoreButton/LoadMoreButton';
import { InlineEdit } from 'components/ContentCreator/InlineEdit';

// utils
import { SlickSettignsParams, slickSettings } from 'utils/slickSettings';
import { useSectionId } from 'utils/hooks/use-section-id';
import { TitleFormats } from '../Title/title.types';

export default function TilesView({
  border,
  showLoadMoreButton,
  loadOneMoreRow,
  currentTiles,
  amount,
  title,
  subtitle,
  catalogName = 'st_tiles',
  carousel,
  autoplay,
  isMobile,
  anchor,
  isTablet,
  contentIndex,
  titleInAnchor,
  colorVariant = 'white',
  isSectionReference = false,
  isChild = false,
}: Readonly<TilesViewProps>) {
  const sectionId = useSectionId(title, anchor ?? '', titleInAnchor, contentIndex);
  const [mouseDownX, setMouseDownX] = useState(null);
  const [mouseUpX, setMouseUpX] = useState(null);
  const slider = useRef();

  const borderClass = border === 'disable-border' ? 'hide-border' : '';

  const renderTilesWrapper = (children) => {
    const onSwipe: SlickSettignsParams['onSwipe'] = () =>
      slider.current && (isMobile || isTablet) && slider.current.slickPause();

    if (carousel) {
      return (
        <SlickSlider
          ref={slider}
          {...slickSettings({
            slides: currentTiles || [],
            autoplay,
            showArrowsMobile: false,
            onSwipe,
          })}
        >
          {children}
        </SlickSlider>
      );
    }

    return <div className="grid-x grid-margin-x">{children}</div>;
  };

  return (
    <div className={`bg-${colorVariant}-teaser`} id={sectionId}>
      <div className="grid-container">
        <div className={`c-tiles ${borderClass}`}>
          <Title
            title={title}
            subtitle={subtitle}
            Format={isChild ? TitleFormats.h4 : TitleFormats.h2}
            titlePreviewId="#st_headline"
            subtitlePreviewId="#st_subtitle"
            isSectionReference={isSectionReference}
            formatClassName="h3"
          />
          <InlineEdit previewId="#st_tiles" isSectionReference={isSectionReference}>
            {renderTilesWrapper(
              currentTiles?.map((tile, index) => {
                const Component = !tile?.video?.videoId ? TileLink : TileVideo;
                return (
                  <Component
                    tile={tile}
                    key={key(tile)}
                    catalogName={catalogName}
                    index={index}
                    carousel={carousel}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      setMouseDownX(e.screenX);
                    }}
                    onMouseUp={(e) => {
                      e.preventDefault();
                      setMouseUpX(e.screenX);
                    }}
                    mouseDownX={mouseDownX}
                    mouseUpX={mouseUpX}
                    setMouseDownX={setMouseDownX}
                    setMouseUpX={setMouseUpX}
                    isSectionReference={isSectionReference}
                    isChild={isChild}
                  />
                );
              }),
            )}
          </InlineEdit>
          {showLoadMoreButton && currentTiles.length < amount && (
            <LoadMoreButton handleLoadMore={loadOneMoreRow} />
          )}
        </div>
      </div>
    </div>
  );
}
