import React, { useState } from 'react';

// utils
import { useTracking } from '../../../../utils/hooks/useTracking';
import { clickTileLink } from '../trackingActions';
import { classNameBuilder } from '../../../../utils/classNameBuilder';

// components
import VideoLightbox from '../../../LightBox/VideoLightbox';
import { Image } from '../../../Image/Image';
import { InnerHtml } from '../../../InnerHtml/inner-html';
import { CmsLink } from '../../../Link/CmsLink';
import VideoPlayButton from '../../../Video/VideoPlayButton';
import { useUCConsent, VideoConsent } from '../../../../features/cookie-consent';
import { InlineEdit } from '../../../ContentCreator/InlineEdit';
import { useIsMobile } from 'components/App/SizeProvider';

interface TileVideoProps {
  setMouseUpX: any;
  setMouseDownX: any;
  onMouseDown: any;
  onMouseUp: any;
  index: number;
  carousel: boolean;
  tile: any;
  isChild: boolean;
  mouseUpX: number | null;
  mouseDownX: number | null;
  isSectionReference: boolean;
}

function TileVideo({
  index,
  setMouseUpX,
  setMouseDownX,
  onMouseDown,
  onMouseUp,
  tile: {
    picture,
    pictureObject,
    link,
    pictureAlt,
    description,
    content,
    title,
    video: {
      videoId,
      videoPreviewImage: { desktop, mobile },
    },
  },
  carousel,
  isChild,
  mouseUpX = null,
  mouseDownX = null,
  isSectionReference = false,
}: Readonly<TileVideoProps>) {
  const isMobile = useIsMobile();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const track = useTracking();
  const ucConsent = useUCConsent();
  const handleOpen = (e) => {
    e.preventDefault();

    if (mouseDownX !== mouseUpX) {
      setMouseUpX(null);
      setMouseDownX(null);
    } else if (title && mouseDownX === mouseUpX) {
      track.trackEvent(clickTileLink(title));
      setModalIsOpen(true);
    }
  };

  const previewImageBreakpointChange = () => {
    const imageSrc = isMobile ? mobile : desktop || mobile;
    return (
      <Image
        src={imageSrc}
        alt={pictureAlt}
        title={pictureAlt}
        format="16_9"
        className="c-tiles__image"
      />
    );
  };

  const tilePicture = pictureObject || picture;

  return (
    <div
      className={`c-tiles__column small-12 medium-6 large-4 cell ${
        carousel ? ' c-tiles--carousel' : ''
      }`}
    >
      <InlineEdit previewId={isSectionReference ? undefined : `#${index}`}>
        <div className="c-tiles__wrapper c-tiles__wrapper--video">
          {ucConsent.Comfort === false && (
            <div className="c-tiles__link">
              <VideoConsent type="mi24" />
            </div>
          )}
          {ucConsent.Comfort === true && (
            <a
              href="#openVideo"
              onClick={handleOpen}
              className="c-tiles__link" // prevent link click on swipe
              onMouseDown={onMouseDown}
              onMouseUp={onMouseUp}
            >
              <VideoPlayButton />
              {videoId && tilePicture !== '' ? (
                previewImageBreakpointChange()
              ) : (
                <Image
                  className="c-tiles__image"
                  data={tilePicture}
                  alt={pictureAlt}
                  title={description || pictureAlt || ''}
                />
              )}
            </a>
          )}
          <div className="c-tiles__content">
            <InnerHtml
              as={isChild ? 'h5' : 'h3'}
              content={title}
              previewId={isSectionReference ? undefined : '#st_headline'}
              className={classNameBuilder('c-tiles__headline', isChild && 'h3')}
            />
            <InnerHtml
              as="div"
              content={content}
              previewId={isSectionReference ? undefined : '#st_content'}
            />
            {!!link && !carousel && <CmsLink link={link} />}
          </div>
        </div>
      </InlineEdit>
      {modalIsOpen && (
        <VideoLightbox
          isModal
          videoId={videoId}
          title={title}
          onClose={() => setModalIsOpen(false)}
        />
      )}
    </div>
  );
}

export default TileVideo;
